import { useAuthorizationStore } from "@/stores/AuthorizationStore";
import router from "@/router/router";

export const ErrorHandler = (statusCode) => {
    const authStore = useAuthorizationStore();
    if (statusCode == 401) {
        authStore.logout();
    } else if (statusCode == 403) {
        router.push("/timesheets");
    }
}

export default ErrorHandler