import { createRouter, createWebHistory } from "vue-router";
import { useAuthorizationStore } from "@/stores/AuthorizationStore";

const routes = [
    {
        path: "/",
        redirect: "/timesheets"
    },
    {
        path: "/authorization/login",
        component: () => import("../pages/AuthorizationPage.vue"),
        meta: { requiresAuthorization: false }
    },
    {
        path: "/admin",
        redirect: "/admin/users"
    },
    {
        path: "/admin/users",
        component: () => import("../pages/admin/users/UsersPage.vue"),
        meta: { requiresAuthorization: true }
    },
    {
        path: "/admin/logs",
        component: () => import("../pages/admin/logs/LogsPage.vue"),
        meta: {requiresAuthorization: true}
    },
    {
        path: "/admin/users/add",
        component: () => import("../pages/admin/users/UsersAddPage.vue"),
        meta: { requiresAuthorization: true }
    },
    {
        path: "/workers",
        component: () => import("../pages/workers/WorkersPage.vue"),
        meta: { requiresAuthorization: true }
    },
    {
        path: "/timesheets",
        component: () => import("../pages/timesheets/TimesheetPage.vue"),
        meta: {requiresAuthorization: true}
    },
    {
        path: "/apartments",
        component: () => import("../pages/apartments/ApartmentsPage.vue"),
        meta: {requiresAuthorization: true}
    },
    {
        path: "/apartments/agreements",
        component: () => import("../pages/apartments/AgreementsPage.vue"),
        meta: {requiresAuthorization: true}
    },
    {
        path: "/apartments/self_accommodations",
        component: () => import("../pages/apartments/SelfAccommodationsPage.vue"),
        meta: {requiresAuthorization: true}
    },
    {
        path: "/daily_allowance",
        component: () => import("../pages/dailyAllowance/DailyAllowancePage.vue"),
        meta: {requiresAuthorization: true}
    },
    {
        path: "/departments",
        component: () => import("../pages/departments/DepartmentsPage.vue"),
        meta: {requiresAuthorization: true}
    }
]

const router = createRouter({
    routes,
    history: createWebHistory()
});

router.beforeEach(async (to) => {
    const authStore = useAuthorizationStore();

    if (to.meta.requiresAuthorization && !authStore.user) {
        router.push("/authorization/login");
    }
});

export default router