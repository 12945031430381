const RequestURL = {
    LOGIN: `${process.env.VUE_APP_BASE_URI}/authorization/login/`,

    GET_TIMESHEET: `${process.env.VUE_APP_BASE_URI}/timesheet/`,
    SAVE_TIMESHEET: `${process.env.VUE_APP_BASE_URI}/timesheet/save/`,
    EXPORT_TIMESHEET_PDF: `${process.env.VUE_APP_BASE_URI}/timesheet/export/pdf/`,
    EXPORT_TIMESHEET_EXCEL: `${process.env.VUE_APP_BASE_URI}/timesheet/export/excel/`,

    GET_WORKERS: `${process.env.VUE_APP_BASE_URI}/workers/`,
    GET_LAST_ATTACHED_WORKERS: `${process.env.VUE_APP_BASE_URI}/workers/last_attached/`,
    SAVE_WORKERS: `${process.env.VUE_APP_BASE_URI}/workers/save/`,

    GET_ROLES: `${process.env.VUE_APP_BASE_URI}/admin/users/roles/`,
    GET_USERS_APARTMENT_GROUPS: `${process.env.VUE_APP_BASE_URI}/admin/users/apartment_groups/`,
    GET_DAILY_RATE_TYPES: `${process.env.VUE_APP_BASE_URI}/admin/users/daily_rate_types/`,
    ADD_USER: `${process.env.VUE_APP_BASE_URI}/admin/users/add/`,
    DELETE_USER: `${process.env.VUE_APP_BASE_URI}/admin/users/delete/`,
    GET_USERS: `${process.env.VUE_APP_BASE_URI}/admin/users/`,
    UPDATE_USER: `${process.env.VUE_APP_BASE_URI}/admin/users/update/`,
    IMPORT_USERS: `${process.env.VUE_APP_BASE_URI}/admin/users/import/`,

    GET_LOG_USERS: `${process.env.VUE_APP_BASE_URI}/logs/users/`,
    GET_LOG_ACTION_GROUPS: `${process.env.VUE_APP_BASE_URI}/logs/log_action_groups/`,
    GET_LOGS: `${process.env.VUE_APP_BASE_URI}/logs/`,

    GET_APARTMENT_ADDRESS_TYPES: `${process.env.VUE_APP_BASE_URI}/apartments/apartment_address_types/`,
    GET_APARTMENT_CITIES: `${process.env.VUE_APP_BASE_URI}/apartments/cities/`,
    GET_APARTMENTS: `${process.env.VUE_APP_BASE_URI}/apartments/`,
    DELETE_APARTMENT_ADDRESS: `${process.env.VUE_APP_BASE_URI}/apartments/apartment_address/`,
    SAVE_APARTMENT_ADDRESS: `${process.env.VUE_APP_BASE_URI}/apartments/apartment_address/save/`,
    GET_ACCOMMODATION_USERS: `${process.env.VUE_APP_BASE_URI}/apartments/accommodations/users/`,
    SAVE_ACCOMMODATIONS: `${process.env.VUE_APP_BASE_URI}/apartments/accommodations/save/`,
    EXPORT_APARTMENS: `${process.env.VUE_APP_BASE_URI}/apartments/export/`,

    GET_AGREEMENTS: `${process.env.VUE_APP_BASE_URI}/agreements/`,
    SAVE_PAYMENTS: `${process.env.VUE_APP_BASE_URI}/agreements/payments/save/`,
    EXPORT_AGREEMENTS_USR: `${process.env.VUE_APP_BASE_URI}/agreements/export/`,

    GET_SELF_ACCOMMODATION_USERS: `${process.env.VUE_APP_BASE_URI}/self_accommodations/users/`,
    ADD_SELF_ACCOMMODATION: `${process.env.VUE_APP_BASE_URI}/self_accommodations/add/`,
    DELETE_SELF_ACCOMMODATION: `${process.env.VUE_APP_BASE_URI}/self_accommodations/delete/`,
    GET_SELF_ACCOMMODATIONS: `${process.env.VUE_APP_BASE_URI}/self_accommodations/`,
    UPDATE_SELF_ACCOMMODATION: `${process.env.VUE_APP_BASE_URI}/self_accommodations/update/`,
    EXPORT_SELF_ACCOMMODATIONS: `${process.env.VUE_APP_BASE_URI}/self_accommodations/export/`,

    GET_NOTIFICATIONS: `${process.env.VUE_APP_BASE_URI}/notifications/`,

    GET_DAILY_ALLOWANCE: `${process.env.VUE_APP_BASE_URI}/daily_allowance/`,
    GET_DAILY_ALLOWANCE_PAYMENTS_PERIODS: `${process.env.VUE_APP_BASE_URI}/daily_allowance/payments/periods`,
    SAVE_DAILY_RATES: `${process.env.VUE_APP_BASE_URI}/daily_allowance/daily_rates/save/`,
    SAVE_DAILY_ALLOWANCE_PAYMENTS: `${process.env.VUE_APP_BASE_URI}/daily_allowance/payments/save/`,
    APPROVE_DAILY_ALLOWANCE_PAYMENTS: `${process.env.VUE_APP_BASE_URI}/daily_allowance/payments/approve/`,
    SAVE_DAILY_ALLOWANCE_COMMENTS: `${process.env.VUE_APP_BASE_URI}/daily_allowance/comments/save/`,
    EXPORT_DAILY_ALLOWANCE: `${process.env.VUE_APP_BASE_URI}/daily_allowance/export/`,
    EXPORT_DAILY_ALLOWANCE_PERIODS: `${process.env.VUE_APP_BASE_URI}/daily_allowance/export/periods/`,

    GET_FIELDS: `${process.env.VUE_APP_BASE_URI}/tablefields/`,

    GET_DEPARTMENTS: `${process.env.VUE_APP_BASE_URI}/departments/`,
    ADD_DEPARTMENT: `${process.env.VUE_APP_BASE_URI}/departments/add/`,
    DELETE_DEPARTMENT: `${process.env.VUE_APP_BASE_URI}/departments/delete/`,
    UPDATE_DEPARTMENT: `${process.env.VUE_APP_BASE_URI}/departments/update/`,
}

export default RequestURL