import axios from "axios"
import JwtHelper from "@/utils/helpers/JwtHelper";
import ErrorHandler from "@/utils/helpers/ErrorHandler";

const ApiService = {
    setHeader() {
        let token = JwtHelper.getToken();
        axios.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : "";
    },

    async get(URL, isFile = false) {
        const config = isFile ? { responseType: "blob" } : null;

        this.setHeader();
        try {
            const response = await axios.get(`${URL}`, config);
            return response.data;
        } catch ({ response }) {
            ErrorHandler(response.status)
            throw response;
        }
    },

    async post(URL, parameters) {
        this.setHeader();
        try {
            const response = await axios.post(`${URL}`, parameters);
            return response;
        } catch ({ response }) {
            ErrorHandler(response.status)
            throw response;
        }
    },

    async delete(URL, parameters) {
        this.setHeader();
        try {
            const response = await axios.delete(`${URL}`, parameters);
            return response;
        } catch ({ response }) {
            ErrorHandler(response.status)
            throw response;
        }
    },

    async put(URL, parameters) {
        this.setHeader();
        try {
            const response = await axios.put(`${URL}`, parameters);
            return response;
        } catch ({ response }) {
            ErrorHandler(response.status)
            throw response;
        }
    }
}

export default ApiService