<template>
    <div class="d-flex flex-column vh-100">
        <NavBar v-if="user"/>
        <RouterView />

        <div class="toast-container bottom-0 end-0 p-4">
            <div id="toast" 
                role="alert" 
                aria-live="assertive" 
                aria-atomic="true" 
                class="toast fade" 
                :class="{ 
                    'bg-primary': informationType == InformationTypeEnum.Information,
                    'bg-success': informationType == InformationTypeEnum.Success,
                    'bg-danger': informationType == InformationTypeEnum.Error
                }">
                <div class="toast-body fs-6 text-white">
                    <span v-show="showToastSpinner" class="spinner-border spinner-border-sm"></span>
                    {{ toastMessage }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useAuthorizationStore } from "@/stores/AuthorizationStore";
import { useFieldsStore } from "@/stores/FieldsStore";
import { useDepartmentsStore } from "@/stores/DepartmentsStore";
import { mapState } from "pinia";

import NavBar from "./components/NavBar";

import InformationTypeEnum from "@/utils/enums/InformationTypeEnum";
import RoleEnum from "@/utils/enums/RoleEnum";

import { Toast } from "bootstrap";

export default {
    name: "App",
    components: { 
        NavBar 
    },
    data() {
        return {
            toast: null,
            toastMessage: "",
            showToastSpinner: false,
            informationType: InformationTypeEnum.Information,

            RoleEnum,
            InformationTypeEnum
        }
    },
    computed: {
        ...mapState(useAuthorizationStore, ["user"])
    },
    async mounted() {
        if (this.user) {
            await this.getBaseData();
        }

        this.toast = new Toast(document.getElementById("toast"));
    },
    methods: {
        async showToast(message, showToastSpinner = false, informationType = InformationTypeEnum.Information) {
            this.toastMessage = message;
            this.showToastSpinner = showToastSpinner;
            this.informationType = informationType;

            await this.$nextTick();

            this.toast.show();
        },
        async getBaseData() {
            const fieldStore = useFieldsStore();
            const departmentsStore = useDepartmentsStore();
            
            await fieldStore.getFields();
            await departmentsStore.getDepartments();
        }
    }
}
</script>