import { defineStore } from "pinia";
import ApiService from "@/api/ApiService";
import RequestURL from "@/utils/constants/RequestURL";

export const useNotificationsStore = defineStore("notifications", {
    actions: {
        async getNotifications() {
            return await ApiService.get(RequestURL.GET_NOTIFICATIONS);
        }
    }
});