import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "@/router/router.js";

import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/ru';

import VueExcelEditor from "./components/vue-excel-editor/main";

import "../public/styles/main.css";

const app = createApp(App);

function RouterPiniaPlugin() {
    return { router };
}

const pinia = createPinia();
pinia.use(RouterPiniaPlugin);
app.use(pinia);

app.use(router);

app.use(DatePicker);

app.use(VueExcelEditor);

app.mount("#app");