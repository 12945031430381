<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
            <button
                class="navbar-toggler" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbar" 
                aria-controls="navbar" 
                aria-expanded="false">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbar">
                <ul class="navbar-nav w-100">
                    <li class="nav-item">
                        <router-link class="nav-link" 
                            :class="{ 'active': currentRoute == '/timesheets' }"
                            to="/timesheets">Табели</router-link>
                    </li>
                    <li v-if="user.roleId == RoleEnum.Admin || user.roleId == RoleEnum.Moderator || user.roleId == RoleEnum.Foreman" class="nav-item">
                        <router-link class="nav-link" 
                            :class="{ 'active': currentRoute == '/daily_allowance' }" 
                            to="/daily_allowance">Суточные</router-link>
                    </li>
                    <li v-if="user.roleId == RoleEnum.Admin || user.roleId == RoleEnum.Moderator || user.roleId == RoleEnum.HRSpecialist" class="nav-item">
                        <router-link class="nav-link" 
                            :class="{ 'active': currentRoute == '/admin/users' }"
                            to="/admin/users">Сотрудники</router-link>
                    </li>
                    <li v-if="user.roleId == RoleEnum.Admin || user.roleId == RoleEnum.Moderator || user.roleId == RoleEnum.HRSpecialist" class="nav-item">
                        <router-link class="nav-link" 
                            :class="{ 'active': currentRoute == '/admin/users/add' }" 
                            to="/admin/users/add">Добавить пользователя</router-link>
                    </li>
                    <li v-if="user.roleId == RoleEnum.Admin" class="nav-item">
                        <router-link class="nav-link" 
                            :class="{ 'active': currentRoute == '/departments' }"
                            to="/departments">Организации</router-link>
                    </li>
                    <li v-if="user.roleId == RoleEnum.Admin || user.roleId == RoleEnum.Moderator || user.roleId == RoleEnum.SecuritySpecialist" class="nav-item">
                        <router-link class="nav-link" 
                            :class="{ 'active': currentRoute == '/admin/logs' }" 
                            to="/admin/logs">Логи</router-link>
                    </li>
                    <li v-if="user.roleId == RoleEnum.Admin || user.roleId == RoleEnum.Moderator || user.roleId == RoleEnum.SecuritySpecialist" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Квартиры
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li>
                                <router-link class="nav-link dropdown-item" 
                                :class="{ 'active': currentRoute == '/apartments' }" 
                                to="/apartments">Квартиры</router-link>
                            </li>
                            <li>
                                <router-link class="nav-link dropdown-item" 
                                :class="{ 'active': currentRoute == '/apartments/agreements' }" 
                                to="/apartments/agreements">Договоры</router-link>
                            </li>
                            <li>
                                <router-link class="nav-link dropdown-item" 
                                :class="{ 'active': currentRoute == '/apartments/self_accommodations' }" 
                                to="/apartments/self_accommodations">Самостоятельное проживание</router-link>
                            </li>
                        </ul>
                    </li>
                    <li v-if="user.roleId == RoleEnum.Foreman" class="nav-item">
                        <router-link class="nav-link" 
                            :class="{ 'active': currentRoute == '/workers' }"
                            to="/workers">Рабочие</router-link>
                    </li>
                    <li class="nav-item d-flex ms-lg-auto">
                        <strong class="align-self-center">{{ helloWords }}</strong>
                        <i
                            v-if="user.roleId == RoleEnum.Admin || user.roleId == RoleEnum.Moderator || user.roleId == RoleEnum.SecuritySpecialist"
                            id="notificationsPopover"
                            class="bi bi-bell cursor-pointer ms-3 mt-2"
                            data-bs-container="body" 
                            data-bs-toggle="popover" 
                            data-bs-placement="bottom" 
                            data-bs-html="true"
                            data-bs-custom-class="custom-popover"
                            data-bs-content=" ">
                        </i>
                        <button class="btn btn-outline-danger shadow-sm ms-3" @click="logout">Выйти</button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { useAuthorizationStore } from "@/stores/AuthorizationStore";
import { useNotificationsStore } from "@/stores/NotificationsStore";
import { mapState } from "pinia";

import RoleEnum from "@/utils/enums/RoleEnum";

import { Popover } from "bootstrap";

export default {
    data() {
        return {
            RoleEnum,

            notificationsPopover: null
        }
    },
    computed: {
        ...mapState(useAuthorizationStore, ["user"]),

        helloWords() {
            const date = new Date();

            let timeWords = "";
            if (date.getHours() < 6) {
                timeWords = "Доброй ночи";
            } else if (date.getHours() < 12) {
                timeWords = "Доброе утро";
            } else if (date.getHours() < 18) {
                timeWords = "Добрый день";
            } else if (date.getHours() < 24) {
                timeWords = "Добрый вечер";
            }

            let fullname = `${this.user.name}${this.user.patronymic ? ` ${this.user.patronymic}` : ""}`;

            return `${timeWords}, ${fullname}!`;
        },

        currentRoute() {
            return this.$route.fullPath;
        }
    },
    async mounted() {
        const notificationStore = useNotificationsStore();

        const notificationsPopover = document.getElementById("notificationsPopover");
        if (notificationsPopover) {
            this.notificationsPopover = new Popover(notificationsPopover);

            const notifications = await notificationStore.getNotifications();
            const popoverContent = notifications && notifications.length
                ? notifications.join("<br>")
                : "Уведомлений на данный момент нет"
            this.notificationsPopover.setContent({
                ".popover-body": popoverContent
            });
        }
    },
    methods: {
        logout() {
            const authStore = useAuthorizationStore();
            authStore.logout();
        }
    }
}
</script>