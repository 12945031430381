import { defineStore } from "pinia";
import ApiService from "@/api/ApiService";
import RequestURL from "@/utils/constants/RequestURL";

export const useFieldsStore = defineStore("fields", {
    state: () => ({
        timesheetTableFields: {},
        dailyAllowanceTableFields: {},
        agreementsTableFields: {}
    }),
    actions: {
        async getFields() {
            const fields = await ApiService.get(RequestURL.GET_FIELDS);
            
            this.timesheetTableFields = fields.timesheetTableFields;
            this.dailyAllowanceTableFields = fields.dailyAllowanceTableFields;
            this.agreementsTableFields = fields.agreementsTableFields;
        }
    }
});