import { defineStore } from "pinia";
import ApiService from "@/api/ApiService";
import RequestURL from "@/utils/constants/RequestURL";

export const useDepartmentsStore = defineStore("departments", {
    state: () => ({
        departments: []
    }),
    actions: {
        async getDepartments() {
            this.departments = await ApiService.get(`${RequestURL.GET_DEPARTMENTS}`);
        },
        async addDepartment(department) {
            const { data } = await ApiService.post(RequestURL.ADD_DEPARTMENT, department);

            this.departments.push(data);
        },
        async deleteDepartment(departmentId) {
            await ApiService.delete(`${RequestURL.DELETE_DEPARTMENT}${departmentId}`);

            this.departments = this.departments.filter(d => d.id != departmentId);
        },
        async updateDepartment(department) {
            const { data } = await ApiService.put(RequestURL.UPDATE_DEPARTMENT, department);

            const index = this.departments.indexOf(this.departments.find(d => d.id == department.id));
            this.departments[index] = data;
        },
    }
});