import { defineStore } from "pinia";
import ApiService from "@/api/ApiService";
import RequestURL from "@/utils/constants/RequestURL";

import JwtHelper from "@/utils/helpers/JwtHelper";

export const useAuthorizationStore = defineStore("authorization", {
    state: () => ({
        user: JSON.parse(localStorage.getItem("user"))
    }),
    actions: {
        async login(loginModel) {
            const response = await ApiService.post(RequestURL.LOGIN, loginModel)

            this.user = response.data.user;
            
            localStorage.setItem("user", JSON.stringify(this.user));
            JwtHelper.setToken(response.data.token);

            this.router.push("/timesheets");
        },
        logout() {
            this.user = null;

            localStorage.removeItem("user");
            JwtHelper.removeToken();

            this.router.push("/authorization/login");
        }
    }
});